<template>
    <r-e-dialog title="下发门锁权限" :visible.sync="dialogVisible" show-footer width="600px"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="申请人" prop="name">
                    <el-input v-model="formPublish.name" placeholder="请填写姓名"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="所属单位" prop="company">
                    <el-input v-model="formPublish.company" placeholder="请填写单位名称"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="申请类型" prop="type">
                    <el-select v-model="formPublish.type" placeholder="请选择">
                        <el-option label="中介" value="中介"/>
                        <el-option label="装修/软装" value="装修/软装"/>
                        <el-option label="施工" value="施工"/>
                        <el-option label="其他" value="其他"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号码" prop="phone">
                    <div class="flex justify-between">
                        <el-input v-model="formPublish.phone" placeholder="请填写手机号码" style="width: 85%;"/>
                        <el-button type="primary" size="small" @click="saveActivity">查询</el-button>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="生效时间" prop="startTime">
                    <el-date-picker v-model="formPublish.startTime" type="datetime" placeholder="选择日期"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="失效时间" prop="endTime">
                    <el-date-picker v-model="formPublish.endTime" type="datetime" placeholder="选择日期"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="申请房间号" prop="roomNumberUuid">
                    <el-select v-model="formPublish.roomNumberUuid" multiple placeholder="请选择房间号" filterable>
                        <el-option v-for="(item, index) in roomList" :key="index" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="申请原因" prop="reason">
                    <el-input type="textarea" v-model="formPublish.reason" :rows="3" placeholder="请填写申请原因"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageWarning} from "@custom/message";
import {sendPermission, newRoomlist, phoneNumber} from "@/api/lock-permission"
import { timeFormat } from "@custom/index";
export default {
    name: "dialog-save-activity",
    data() {
        return {
            searchText: '',
            uuid: '',
            dialogVisible: false,
            roomList: [],
            formPublish: {
                company: null,
                name: null,
                phone: null,
                startTime: null,
                type: null,
                endTime: null,
                roomNumberUuid: null,
                reason: null,
            },
            rules: {
                name: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
                phone: [{required: true, message: '手机号不能为空', trigger: 'blur'}],
                startTime: [{required: true, message: '生效时间不能为空', trigger: 'blur'}],
                endTime: [{required: true, message: '失效时间不能为空', trigger: 'blur'}],
                type: [{required: true, message: '人员类型不能为空', trigger: 'blur'}],
                company: [{required: true, message: '单位名称不能为空', trigger: 'blur'}],
                roomNumberUuid: [{required: true, message: '房间号不能为空', trigger: 'blur'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            newArr: [],
        }
    },

    created() {},
    async mounted() {
        let roomListInfo = await newRoomlist();
        this.roomList = roomListInfo.list;
    },
    methods: {
        openDialog() {
            this.dialogVisible = true;
        },
        // 查询手机号回显信息
        saveActivity() {
            const that = this;
            const phone = this.formPublish.phone;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading(loadingOptions);
            phoneNumber({phone}).then(res => {
                const {info} = res;
                if (info) {
                    const {company, name, phone, type,} = info;
                    that.formPublish.company = company;
                    that.formPublish.name = name;
                    that.formPublish.phone = phone;
                    that.formPublish.type = type;
                } else {
                    MessageWarning("该手机号未注册", 1000);
                }
            }).finally(() => loading.close());
        },
        handleSubmitPublish() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {name, type, phone, startTime, endTime, reason, roomNumberUuid} = that.formPublish;
                    startTime = timeFormat(new Date(startTime),'yyyy-MM-dd HH:mm:ss');
                    endTime = timeFormat(new Date(endTime),'yyyy-MM-dd HH:mm:ss');
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                    let paramData = {name, type, phone, startTime, endTime, reason, roomNumberUuid};
                    sendPermission(paramData).then(res => {
                        MessageSuccess("添加成功");
                        that.handleCancel();
                    }).finally(() => loading.close());
                }
            });

        },
        handleCancel() {
            this.dialogVisible = false;
            this.formPublish = {
                company: null, name: null, roomNumberUuid: null, reason: null, phone: null, startTime: null, endTime: null, type: null,
            }
            this.$emit('handleSearch');
        },
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>